@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Open+Sansdisplay=swap");

html {
  scroll-behavior: smooth;
  //scroll-snap-type: y mandatory;
}

.App {
  text-align: center;
}

// ::selection {
//   background: transparent;
// }

//NavBar
.navbar {
  height: 100px;
  transition: height 0.3s;

  #logo {
    transition: 0.3s;
  }

  a {
    font-size: large;
    transition: all 0.3s ease;
  }
  .nav-links {
    top: 100px;
  }
  .active {
    position: relative;
    font-weight: 900;
    font-size: x-large;
    transition: all 0.3s ease;
    top: -0.15em;
  }
}

.small {
  font-size: 14px;
}

.abn {
  font: bold 11px Arial;
  position: relative;
  top: -2.5px;
}
.navbar.smaller {
  height: 55px;

  #logo {
    width: 55px;
    transition: 0.3s;
  }

  a {
    font-size: larger;
  }

  .nav-links {
    top: 55px;
  }
}

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//Misc
#home {
  padding-top: 20vh;
  background-repeat: no-repeat;
  background-size: cover;
  animation: changeBackground 45s infinite; /* Change every 30 seconds */
}

@keyframes changeBackground {
  0% {
    background-image: url("../src/images/stockPhoto1.jpg");
  }
  30% {
    background-image: url("../src/images/stockPhoto1.jpg");
  }
  33.33% {
    background-image: url("../src/images/stockPhoto2.jpg");
  }
  63% {
    background-image: url("../src/images/stockPhoto2.jpg");
  }
  66.66% {
    background-image: url("../src/images/stockPhoto3.jpg");
  }
  96.33% {
    background-image: url("../src/images/stockPhoto3.jpg");
  }
  100% {
    background-image: url("../src/images/stockPhoto1.jpg");
  } /* Loop back to the first image */
}

#contact {
  //Else section snap occurs and full page is not shown
  padding-top: 300px;
  background-image: url("../src/images/contactpage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

// #sectionContent {
//   height: 100vh;
// }

section {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-type: mandatory;
  scroll-snap-stop: always;
}

.bgVid {
  position: absolute;
  z-index: -1;
}

//Nav
// .navbar {
//   position: fixed; /* Set the navbar to fixed position */
//   z-index: 10;
// }

//Footer
footer {
  text-align: center;
  font-weight: 600;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1vh 0 1vh 0;
  z-index: 999;
  //Aero
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  svg {
    margin-left: 15px;
    margin-right: 15px;
  }
  a {
    color: inherit;
  }
}

//Theme Toggle
$bg: mediumblue;
$fg: lighten($bg, 20%);
$borderWidth: 3px;

input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

label {
  color: lighten($bg, 40%);
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 20px;
  margin-bottom: 0 !important;
}

input[type="radio"]:checked + label {
  color: lighten($bg, 60%);
  background: $fg;
}

label + input[type="radio"] + label {
  border-left: solid $borderWidth $fg;
}
.radio-group {
  border: solid $borderWidth $fg;
  display: inline-block;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
}

//Project Cards
.projectCards {
  margin-top: 8vh !important;
  margin-right: auto;
  margin-left: auto;
  //max-width: 20vw;
  width: 35vh !important;
}

//Laptop/Desktop
@media (min-width: 992px) {
  .projectCards {
    margin-top: 7vh !important;
    width: 18rem !important;
  }
  .mobileChevron {
    display: none;
  }
}

//Tablets
@media (min-width: 485px) and (max-width: 992px) {
}

//Phones
@media (max-width: 485px) {
  .hello {
    h1 {
      font-size: 15vw !important;
    }
    p {
      font-size: 5vw !important;
    }
  }

  .helloC {
    h1 {
      font-size: 10vw !important;
    }
  }
}

//////////
//Themes//
//////////

//DarkTheme
.dark {
  --color-primary: black;
  background-color: black;

  .nav {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }

  .box {
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.15);
  }

  .hello-sub {
    color: #bababa;
  }

  .hello {
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 50px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    margin-left: 5vw;
    margin-right: 5vw;
    h1 {
      font-size: 8vw;
    }

    img {
      max-height: 40vh;
      max-width: 100%;
    }

    p {
      font-size: 20px !important;
    }
  }

  .projectCards {
    background: rgba(120, 120, 120, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: unset !important;
  }

  //Contact form button
  .btn-mod {
    background: rgba(120, 120, 120, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  //Alert Styling
  #__react-alert__ > div > div > div {
    background: rgba(3, 218, 198, 0.6) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #fff !important;

    svg {
      stroke: #fff !important;
    }
  }

  //Modal Close Button Styles
  .modalStyle_closeButton__3ETmZ {
    filter: invert(100%);
  }

  footer {
    color: #0d6efd;
    background: rgba(0, 0, 0, 0.5);
  }

  //Nav
  .navbar {
    //background-color: #000;
    color: white;
    box-shadow: 0 2px 6px 0 rgb(255 255 255 / 7%);
  }

  .navbar--menu {
    .phone {
      color: #29469c;
    }
    .phone svg path {
      fill: #29469c;
    }
  }

  //contact
  .helloC {
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0px 15% 20px 15%;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    margin-left: 5vw;
    margin-right: 5vw;
    h1 {
      font-size: 6.5vw;
    }
  }

  .right-container button {
    //error in nav
    border-radius: 20px;
    border: 1px solid #0d6efd;
    background-color: #0d6efd;

    &:disabled {
      color: #000;
      background-color: #141d26;
      border: 1px solid #000;
    }
  }
  input,
  textarea,
  .textarea {
    background-color: #141d26;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    font-size: 0.8rem;
    max-width: 100%;
    min-width: 100%;
    color: white;
  }

  .left-container {
    //background-color: rgba(13, 110, 253, 0.5);
    // backdrop-filter: blur(5px);
  }

  .parallelogram {
    background-color: #152640;
    svg {
      color: #152640;
    }

    span {
      color: white;
    }
  }
}

//LightTheme

.light {
  --color-primary: white;
  background-color: white;

  //Change Nav styling
  #root > div > div > div > ul > li > a {
    color: #0d6efd;
    border-color: #0d6efd;
  }

  .nav {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 95%,
      rgba(255, 255, 255, 0.4) 100%
    );
  }

  .toggle {
    //background-color: var(--color-primary);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  //Home
  .hello {
    background: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 50px;
    color: orange;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    margin-left: 5vw;
    margin-right: 5vw;
    h1 {
      font-size: 8vw;
    }
    img {
      max-height: 40vh;
      max-width: 100%;
    }

    p {
      font-size: 20px !important;
    }
  }
  .projectCards {
    background: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: unset !important;
  }

  //Contact
  .helloC {
    background: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0px 15% 20px 15%;
    color: orange;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    margin-left: 5vw;
    margin-right: 5vw;
    h1 {
      font-size: 6.5vw;
    }
  }

  input,
  textarea,
  .textarea {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    font-size: 0.8rem;
    max-width: 100%;
    min-width: 100%;
  }

  //Set Color for Scroll
  #helloScroll a span {
    border-left: 2px solid #000 !important;
    border-bottom: 2px solid #000 !important;
  }

  //Nav
  .navbar--menu {
    .phone {
      color: dimgray;
    }

    .phone svg path {
      fill: dimgrey;
    }
  }
}

//Animated Scroll Arrow
// [1]"demo:CSS scroll down button", CodePen, 2020. [Online]. Available: https://codepen.io/nxworld/pen/OyRrGy. [Accessed: 30- Jul- 2020].

#helloScroll a {
  padding-top: 60px;
}
#helloScroll a span {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

//Contact Page
#contact {
  padding-top: 15vh !important;
}

// ==================
// Dark mode toggle
//  ==================

.toggle {
  //background-color: var(--color-primary);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  bottom: 2.75em;
  left: 1.1em;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

:root {
  --color-primary-light: #152640;
  --color-secondary: #eec413;

  &.theme--night {
    --color-primary: #0a192f;
    --color-primary-light: #152640;
    --color-secondary: #e684af;
  }
}

// ==================
// base
//  ==================

* {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

// ==================
// theme-toggle
//  ==================

body {
  background: var(--color-primary);
}

.theme-toggle {
  height: 37px;
  width: 37px;
  position: fixed;
  bottom: 2.1em;
  left: 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
  .sun {
    background: var(--color-secondary);
    width: 37px;
    height: 37px;
    border-radius: 50%;
    border: 4px solid var(--color-primary);
  }

  .sun__ray {
    width: 2px;
    background: var(--color-secondary);
    display: block;
    height: 121%;
    position: absolute;
    z-index: -1;
    transition: 0.4s all, height 0.3s ease-in-out;

    $columns: 12;
    @for $i from 1 through $columns {
      &:nth-child(#{$i}) {
        transform: rotate(calc(#{$i} * calc(360deg / #{$columns})));
      }
    }
  }

  &:hover .sun__ray {
    $columns: 12;

    @for $i from 1 through $columns {
      &:nth-child(#{$i}) {
        transform: rotate(
          calc(calc(#{$i} * calc(360deg / #{$columns})) - 20deg)
        );
      }
    }
  }
}

.moon {
  height: 28px;
  width: 28px;
  position: absolute;
  background: var(--color-primary);
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: scale(0) translate(25%, -25%);
  z-index: 9;
  transition: 0.4s transform;
  transform-origin: right;
}

.theme--night {
  .theme-toggle {
    background-color: var(--color-primary);
  }

  .theme-toggle:hover {
    .moon {
      transform: scale(1) translate(-3%, -18%);
    }
  }

  .moon {
    transform: scale(1) translate(11%, -11%);
  }

  .theme-toggle .sun__ray {
    height: 0;
    transition: 0.4s, transform 0.4s, height 0.2s 0.1s;

    $columns: 12;
    @for $i from 1 through $columns {
      &:nth-child(#{$i}) {
        transform: rotate(
          calc(calc(#{$i} * calc(360deg / #{$columns})) - 45deg)
        );
      }
    }
  }
}

#root > nav > div > span.nav-links__group.left > a > svg {
  position: relative;
  top: 4px;
  font-size: larger;
}

//ANIMATED PHONE ICON
/* Base Styling */

.phone {
  float: right;
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  font-weight: bold;

  margin: 33px 0 33px 50px;
}
.phone svg {
  position: relative;
  width: 18px;
  height: 18px;
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}
.phone svg path {
}
.phone svg path:nth-child(2) {
  -webkit-animation: header-phone 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
  animation: header-phone 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
}
.phone svg path:nth-child(3) {
  -webkit-animation: header-phone2 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
  animation: header-phone2 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
}

/* Animate phone */

@-webkit-keyframes header-phone {
  0%,
  30% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  80% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes header-phone {
  0%,
  30% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  80% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes header-phone2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  70%,
  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes header-phone2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  70%,
  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}

//Contact form

$teal: #00b4cf;
$white: transparent;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

container {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  min-width: 370px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.form-container {
  display: flex;
}

// .left-container {
//   flex: 1;
//   height: 480px;
//   background-color: $teal;
// }
.right-container {
  display: flex;
  flex: 1;
  height: 460px;
  background-color: $white;
  justify-content: center;
  align-items: center;
}

.left-container {
  display: flex;
  flex: 1;
  height: 440px;
  justify-content: center;
  align-items: center;
  color: black;
  //backdrop-filter: blur(5px);
}

.left-container p {
  font-size: 0.9rem;
}

.right-inner-container {
  width: 70%;
  text-align: center;
}

.left-inner-container {
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
}

input:focus,
textarea:focus {
  outline: 1px solid #0d6efd;
}

.error {
  color: red !important;
  font-family: "Gilroy";
}

button {
  border-radius: 20px;
  border: 1px solid #00b4cf;
  background-color: #00b4cf;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

button:disabled {
  border: 1px solid lightgray;
  background-color: lightgray;
  cursor: not-allowed;
}

button:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 600px) {
  .left-container {
    display: none;
  }
  .lg-view {
    display: none;
  }

  .right-inner-container {
    width: 100%;
    text-align: center;
  }
}

.sm-view {
  margin-bottom: 10px;
  svg {
    font-size: 200%;
  }
}

@media only screen and (min-width: 600px) {
  .sm-view {
    display: none;
  }
}

form p {
  text-align: left;
}

.parallelogram {
  width: 100%;
  height: 20%;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: white;
  overflow: hidden;
  position: relative;
  margin-top: 10%;

  a {
    width: 100%;
    height: 100%;
    display: inline-flex;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
  }
  span {
    width: 80%;
    display: inline-block;
    color: #444444;
    &:hover {
      color: #eec413;
    }
  }
  p {
    display: inline-block;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
  }

  svg {
    color: white;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    font-size: 200%;
    margin-top: 45%;
  }
}

.inner {
  display: inline-block;
  height: 100%;
  width: 20%;
  background-color: orange;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.lt {
  margin-left: -20% !important;
  padding-left: 20%;
}
.rt {
  margin-left: 20% !important;
  padding-right: 20%;
}

#lt {
}

#rt {
}

//Modal
////////////////////
// Colour Vars
////////////////////
$light: #eee;
$light-shade-2: #ccc;
$light-shade-3: #bababa;
$dark: #444444;

////////////////////
// Modal
////////////////////
#modal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  display: grid;
  place-content: center;
  /// Hide
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

////////////////////
// Modal Content
////////////////////
.modal-content {
  background: #ffffff;
  border-radius: 10px;
  width: 60vw;
  max-width: 600px;
  height: 35vh;
}
////////////////////
// Modal Close Button
////////////////////

.modal-content .close {
  z-index: 9999;
  display: block;
  text-decoration: none;
  background-color: tomato;
  padding: 0.8em 1.2em;
  border-radius: 10px;
  color: #fff;
  position: relative;
  font-size: 0.9rem;
  &:hover {
    background-color: crimson;
  }
  &:active {
    top: 1px;
  }
}

////////////////////
// Page Set Up - Ignore
////////////////////

a.open-modal {
  display: inline-flex;
  color: $dark;
  position: relative;
  &:hover {
    color: white;
  }
}

#iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

//Bubbly button
$fuschia: orange;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

.bubbly-button {
  font-family: "Helvetica", "Arial", sans-serif;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1em;
  padding: 3%;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;

  &:focus {
    outline: 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }

  &:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, $button-bg 20%, transparent 30%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
      15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }

  &:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
      20% 20%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  &:active {
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }

  &.animate {
    &:before {
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

//Page contents

#sectionContent {
  padding-top: 60px;
  color: #0d6efd;
  font-size: 1.5rem;
  margin-left: 1em;
  margin-right: 1em;
}

//About page
.aboutText {
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 1em;
}

.aboutImage {
  display: none;
}

.aboutIMG {
  width: 90vw;
  height: auto;
  padding-bottom: 50px;
}

//Only on desktop
@media (min-width: 992px) {
  #sectionContent {
    padding-top: 100px;
    color: #0d6efd;
    font-size: 1.5rem;
    margin-left: 1em;
    margin-right: 1em;
  }

  .sectionContent {
    font-size: 1.2rem;
    display: table;
    clear: both;
  }
  .aboutText {
    text-align: left;
    float: left;
    width: 40%;
    padding: 2rem;
  }

  .aboutC {
    display: flex;
    align-items: center;
  }

  .aboutIMG {
    //background-image: url(../public/images/stockPhoto.jpg);
    width: 60%;
    height: auto;
    float: left;
    padding: 2rem;
    display: block;
  }
}

//Service Cards
.serviceContent {
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.box {
  flex-basis: 25%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  min-width: 80%;
}

//Layout when not mobile
@media (min-width: 992px) {
  .box {
    min-width: 30%;
  }
}
//End

.box .icon {
  width: 70px;
  height: 70px;
  border-radius: 5% 45%;
  background: #000;
  color: #fff;
  font-size: 55px;
  font-weight: 500;
  position: relative;
  margin: 0 auto;
  transition: 1s;
}
.box:nth-child(1) .icon {
  box-shadow: 0 0 0 0 #e91e63;
  background: #e91e63;
}

.box:nth-child(1):hover .icon {
  box-shadow: 0 0 0 400px #e91e63;
  background: #e91e63;
}

.box:nth-child(5) .icon {
  box-shadow: 0 0 0 0 #23e629;
  background: #23e629;
}

.box:nth-child(5):hover .icon {
  box-shadow: 0 0 0 400px #23e629;
  background: #23e629;
}

.box:nth-child(3) .icon {
  box-shadow: 0 0 0 0 $teal;
  background: $teal;
}

.box:nth-child(3):hover .icon {
  box-shadow: 0 0 0 400px $teal;
  background: $teal;
}

.box:nth-child(4) .icon {
  box-shadow: 0 0 0 0 #2396f3;
  background: #2396f3;
}

.box:nth-child(4):hover .icon {
  box-shadow: 0 0 0 400px #2396f3;
  background: #2396f3;
}

.box:nth-child(2) .icon {
  box-shadow: 0 0 0 0 orange;
  background: orange;
}

.box:nth-child(2):hover .icon {
  box-shadow: 0 0 0 400px orange;
  background: orange;
}

.box:nth-child(6) .icon {
  box-shadow: 0 0 0 0 #2396f3;
  background: #2396f3;
}

.box:nth-child(6):hover .icon {
  box-shadow: 0 0 0 400px #2396f3;
  background: #2396f3;
}

.box-content {
  position: relative;
  transition: 0.5s;
  z-index: 1;
  margin-bottom: 20px;
  font-size: 16px;
}

.box-content h3 {
  text-transform: capitalize;
}

.box:hover .box-content {
  color: #fff;
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  //z-index: 1;
}

.box a {
  text-decoration: none;
  color: #000;
  padding: 8px 15px;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  //z-index: 1;
}
/*.box:hover a {
  color:#000;
  border:1px solid #fff;
}
.box a:hover{
  background:#fff;
  color:#000
}*/

.serviceContent {
  padding-bottom: 50px;
}

//Carousel changes
#imageGallery > div > div > div > div:nth-child(2) > div > ul > li {
  img {
    height: 80px !important;
    width: 80px !important;
  }
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #0d6efd !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #0d6efd !important;
}

.carousel-container {
  margin-bottom: 30px;
}

.nav {
  height: 100px !important;
  --height: 100px !important;
}
